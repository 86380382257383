<template>
  <div class="naver-login-page">redirect to...</div>
</template>

<script>
import { cccvLogin } from "@/common/api.service";
import { setAuth } from "@/common/auth.service";

export default {
  name: "Naver",
  async mounted() {
    try {
      const code = this.$route?.query?.code;
      if (!code) {
        throw new Error();
      }

      const login = await cccvLogin("naver", {
        code,
        state: "RANDOM_STATE"
      });

      if (login && login.data) {
        setAuth(login.data);
        this.$router.push("/admin/user");
      }
    } catch (err) {
      alert("로그인에 실패하였습니다.");
      window.location.href = "/";
    }
  }
};
</script>

<style></style>
